<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="form-dialog form-dialog--new-user">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>

      <h1 class="form-dialog__title">Add parking warden integration</h1>
      <span class="form-dialog__subtitle">
            Select from the available options in the list
      </span>
      <v-form class="mt-8">
        <v-row dense>
          <v-col cols="12" style="height: 64px">
            <div class="text-input-label">Warden integrations</div>
            <v-autocomplete
            v-model="selectedWarden"
            class="form-font pkmn-input"
            background-color="#F6F6F6"
            label="Select from list"
            item-text="name"
            item-value="id"
            :items="filteredItems"
            :search-input.sync="search"
            placeholder="search..."
            clearable
            solo
            flat
            dense
          >
      </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" style="height: 44px"></v-col>
      </v-row>
        <v-row class="form-dialog__button-wrap">
        <v-col cols="6">
          <v-btn class="pkmn-button" outlined color="primary" @click="close">
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            class="pkmn-button pkmn-button--confirm pkmn-button--has-border"
            color="primary"
            @click="add"
            :disabled="selectedWarden === null"
          >
            Add
          </v-btn>
        </v-col>
      </v-row>
      </v-form>

    </v-card>
  </v-dialog>
</template>

<script>
import dropdownNarrow from '@/assets/dropdown_arrow.svg'

export default {
  name: 'FormAddWardenPopup',
  props: {
      wardenList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      alwaysOpen: true,
      selectedWarden: null,
      dropdownNarrow,
      search: null,
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    filteredItems() {
      if (!this.search) return this.wardenList;
      return this.wardenList.filter(item => 
        item.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    resetForm() {
      this.search = null;
      this.selectedWarden = null;
    },
    add() {
      this.$emit('add', this.selectedWarden)
    }
  }
}
</script>
<style lang="scss">
@import '@/style/common';

.v-text-field__details {
  padding: 0px !important;
}

.col:has(.v-input--is-focused) {
  .text-input-label {
    color: $color-brand;
  }
}

.warden-picker {
  padding: 24px
}
.form-dialog--new-user .v-input--is-focused .v-input__slot {
  border: 1px solid $color-brand !important;
}

.form-dialog--new-user .v-input.error--text .v-input__slot {
  border: 1px solid $color-danger !important;
}

.my-text-field ::v-deep .v-text-field__details .v-messages {
  display: flex;
  justify-content: flex-end;
}
</style>
