<template>
  <div class="hubs-table">
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :items="hubsList"
      fixed-header
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:item.hub_locations="{ item }">
        <v-tooltip bottom max-width="500" :key="item.id">
          <template v-slot:activator="{ on }">
            <div class="hubs-table-locations" v-on="on">
              {{ item.hub_locations.join(', ') }}
            </div>
          </template>
          {{ item.hub_locations.join(', ') }}
        </v-tooltip>
      </template>
      <template v-slot:item.id="{ item }">
        <div class="mobile-row__action d-flex justify-end">
          <v-btn
              class="pkmn-button pkmn-button--no-padding"
              text
              color="warning"
              @click="deletehub(item)"
            >
              <img src="@/assets/button-delete.svg" />
            </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'SubtabHubs',
  props: {
    hubsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      auth: auth,
      locationHeaders: [
        { text: this.$t('table_hub_title'), value: 'hub_name' },
        { text: this.$t('table_hub_code'), value: 'hub_location_code' },
        { text: '', value: 'id', sortabl: false }
      ],
      merchantHeaders: [
        { text: this.$t('table_hub_title'), value: 'hub_name' },
        { text: this.$t('table_hub_code'), value: 'hub_locations' },
        { text: '', value: 'id', sortabl: false }
      ],
      mobileBreakpoint: 767
    }
  },
  computed: {
    headers() {
      return this.$route.name === 'Merchant'
        ? this.merchantHeaders
        : this.locationHeaders
    }
  },
  methods: {
    deletehub(record) {
      this.$emit('delete', record)
    }
  }
}
</script>

<style lang="scss">
.hubs-table {
  table {
    table-layout: fixed;
  }
  &-locations {
    max-width: 100%;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
