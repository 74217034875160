<template>
  <div>
    <div>
      <v-row>
        <v-col cols="23">
          <data-table-discounts
            :data="discounts"
            :showEdit="false"
            :isLocationProduct="isLocationProduct"
            @edit="editDiscount"
            @close="close"
            @showLocations="showLocations"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import DataTableDiscounts from '@/components/Merchant/DataTable/DataTableDiscounts'

export default {
  name: 'MerchantDiscounts',
  props: {
    discounts: {
      type: Array,
      required: true
    },
    isLocationProduct: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DataTableDiscounts
  },
  methods: {
    editDiscount(discount) {
      this.$emit('edit', discount)
    },
    close() {
      this.$emit('close')
    },
    showLocations(item) {
      this.$emit('showLocations', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}
</style>
