<template>
  <div>
    <v-tabs class="tabs" v-model="currentItem" left slider-color="white">
      <v-tab class="smaller-tab" key="hubs">
        HUB
      </v-tab>
      <v-tab class="smaller-tab" key="wardens">
        Warden
      </v-tab>
      <v-tab v-show="false" class="smaller-tab"> Camera </v-tab>
      <v-col class="button-panel">
        <v-row>
          <v-spacer />
          <v-btn
          v-if="currentItem === 0 && auth.isParkmanAdmin() && hubsList.length === 0"
            class="pkmn-button-small"
            color="primary"
            @click="addHub"
          >
            Add HUB
          </v-btn>
        </v-row>
      </v-col>
    </v-tabs>

    <v-tabs-items class="tab-integrations-items" v-model="currentItem">
      <v-tab-item class="tab-item" key="hubs">
        <v-divider class="mb-4" />
        <subtab-hubs
        v-if="hubsList.length > 0" 
        :hubsList="hubsList" 
        @delete="deleteHub"
        />
        <p v-else class="form-dialog__subtitle">
          There is no HUB connection set up yet.
        </p>
      </v-tab-item>
      <v-tab-item class="tab-item" key="wardens">
        <v-divider class="mb-4" />
        <subtab-warden
         :wardensList="wardensList"
         @add="addWarden" 
         @delete="deleteWarden"/>
      </v-tab-item>
      <v-tab-item v-show="false" class="tab-item">
        <v-divider class="mb-4" />
        Camera
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SubtabHubs from '@/components/Locations/AddVisitorParking/Subtabs/SubtabHubs.vue'
import SubtabWarden from '@/components/Locations/AddVisitorParking/Subtabs/SubtabWarden.vue'
import auth from '@/auth'

export default {
  name: 'TabIntegrations',
  components: { SubtabHubs, SubtabWarden },
  props: {
    hubsList: {
      type: Array,
      default: () => []
    },
    wardensList: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    auth: auth,
    currentItem: 0,
  }),
  methods: {
    addWarden(warden) {
      this.$emit('add', warden)
    },
    deleteWarden(warden) {
      this.$emit('delete', warden)
    },
    addHub() {
      this.$emit('addHub')
    },
    deleteHub() {
      this.$emit('deleteHub')
    }
  },
  mounted() {
    this.currentItem = 0
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/common';
@import '~@/style/variables';

.tabs {
  margin-left: 0;
  margin-top: 0;
  max-height: 32px;
}

.button-panel {
  padding: 4px 14px 40px;
}
</style>
