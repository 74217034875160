<template>
  <div class="reports__data mt-8">
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :items="data"
      fixed-header
      hide-default-footer
      disable-pagination
      must-sort
      :mobile-breakpoint="mobileBreakpoint"
    >
    <template v-slot:item.discount_list_name="{ item }">
        <span>{{ item.discount_list_name }}</span>
    </template>
    <template v-slot:item.discount_percentage="{ item }">
        <span>{{ `${item.discount_percentage}%` }}</span>
    </template>

    <template v-slot:item.product_name="{ item }">
      <span>{{ productName(item.product_name) }}</span>
    </template>

    <template v-slot:item.type="{ item }">
      <div v-if="isLocationProduct">
        <span>{{ discountType(item) }}</span>
      </div>
      <div v-else-if="item.locations_quantity !== null" @click="showLocations(item)">
        <span class="clickable-text clickable-span">{{ discountType(item) }}</span>
      </div>
      <span v-else>{{ discountType(item) }}</span>
    </template>
    <template v-slot:item.valid_from="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <span class="ellipsed-text" v-bind="attrs" v-on="on">
              {{ toDate(item.valid_from) }}
            </span>
          </template>
          <span>
            {{ toDate(item.valid_from) }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.valid_to="{ item }">
        <v-tooltip bottom content-class="pkmn-tooltip" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <span class="ellipsed-text" v-bind="attrs" v-on="on">
              {{ toDate(item.valid_to) }}
            </span>
          </template>
          <span>
            {{ toDate(item.valid_to) }}
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.action="{ item }">
        <div class="mobile-row__action">
          <div v-if="auth.isParkmanAdmin() || auth.isMerchantAdmin()">
            <v-btn
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="warning"
              @click="editDiscount(item)"
            >
              <img src="@/assets/button-edit.svg" />
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'
import auth from '@/auth'

export default {
  name: 'DataTableDiscounts',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    isLocationProduct: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      auth: auth,
      headers: [
        { text: 'Name of discount list', value: 'discount_list_name' },
        { text: 'Discount %', value: 'discount_percentage' },
        { text: 'Applies to product', value: 'product_name' },
        { text: 'Type', value: 'type' },
        { text: 'Valid from', value: 'valid_from' },
        { text: 'Valid until', value: 'valid_to' },
        { text: '', value: 'action', sortable: false }
      ],
      mobileBreakpoint: 767 // screen width in pixel to switch to mobile view
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    editDiscount(item) {
      this.$emit('edit', item)
    },
    showLocations(item) {
      this.$emit('showLocations', item)
    },
    toDate(timestamp) {
      if (timestamp === null) {
        return ''
      }
      const dateString = moment.unix(timestamp).format('DD.MM.YYYY')
      return dateString
    },
    productName(name) {
      return 'Visitor parking'
    },
    discountType(item) {
      if (this.isLocationProduct) {
        if (item.type === 'merchant') {
          return 'Acount default'
        } else {
          return 'This location (overrides account default)'
        }
      } else {
        if (item.type === 'merchant' && item.locations_quantity === null) {
          return 'Acount default'
        }

        if (item.locations_quantity !== null) {
          return `${item.locations_quantity} locations`
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.reports__data .theme--light:focus:before {
  opacity: 1 !important;
}

::v-deep .tooltip-column {
  max-width: 145px;
}
::v-deep .ellipsed-text {
  width: 100%;
  display: block;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

::v-deep th {
  text-wrap: nowrap;
}

.clickable-text {
  overflow: hidden;
color: var(--button-main, #37BD01);
text-overflow: ellipsis;
white-space: nowrap;
/* Label */
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 14.4px */

}

.clickable-span {
  cursor: pointer;
}
</style>
