// templates.js

export const templates = {
  1: `<parkings>
    <parking>
        <regno>licensePlate</regno>
        <status>1</status>
        <zone>locationCode</zone>
        <city>locationName</city>
        <starttime>YY-MM-DD HH:MM</starttime>
        <stoptime>YY-MM-DD HH:MM</stoptime>
    </parking>
</parkings>`,

  2: `<parkings>
    <parking>
        <CarLicenseNumber>licensePlate</CarLicenseNumber>
        <Status>1</Status>
        <ParkingAreaId>locationCode</ParkingAreaId>
        <ParkingAreaName>locationName</ParkingAreaName>
        <StartParkingDate>YY-MM-DD HH:MM</StartParkingDate>
        <StopParkingDate>YY-MM-DD HH:MM</StopParkingDate>
        <ParkingOperator>ParkMan</ParkingOperator>
    </parking>
</parkings>`,

  3: `<parkings>
    <parking>
        <CarLicenseNumber>licensePlate</CarLicenseNumber>
        <Status>1</Status>
        <ParkingAreaId>locationCode</ParkingAreaId>
        <ParkingAreaName>locationName</ParkingAreaName>
        <StartParkingDate>YY-MM-DD HH:MM</StartParkingDate>
        <StopParkingDate>YY-MM-DD HH:MM</StopParkingDate>
        <PaymentOperator>ParkMan</PaymentOperator>
    </parking>
</parkings>`
};
