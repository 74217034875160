<template>
  <div class="reports__data mt-8">
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :items="data"
      fixed-header
      hide-default-footer
      disable-pagination
      must-sort
      :mobile-breakpoint="mobileBreakpoint"
    >
    <template v-slot:item.holiday_timestamp="{ item }">
        <span>{{ toDate(item.holiday_timestamp) }}</span>
    </template>
    <template v-slot:item.discount_percentage="{ item }">
        <span>{{ `${item.discount_percentage}%` }}</span>
    </template>

    <template v-slot:item.product_name="{ item }">
        <span>{{ productName(item.product_name) }}</span>
    </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'
import auth from '@/auth'

export default {
  name: 'DataTableHolidays',
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      auth: auth,
      headers: [
        { text: 'Date', value: 'holiday_timestamp' },
        { text: 'Discount', value: 'discount_percentage' },
        { text: 'Product', value: 'product_name' }
      ],
      mobileBreakpoint: 767 // screen width in pixel to switch to mobile view
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    editDiscount(item) {
      this.$emit('edit', item)
    },
    toDate(timestamp) {
      const dateString = moment.unix(timestamp).format('DD.MM.YYYY')
      return dateString
    },
    productName(name) {
      return 'Visitor parking'
    }
  }
}
</script>
<style lang="scss" scoped>
.reports__data .theme--light:focus:before {
  opacity: 1 !important;
}

::v-deep .tooltip-column {
  max-width: 145px;
}
::v-deep .ellipsed-text {
  width: 100%;
  display: block;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

::v-deep th {
  text-wrap: nowrap;
}
</style>
