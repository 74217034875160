<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="form-dialog form-dialog--new-user">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>

      <h1 class="form-dialog__title">Date based discount - default</h1>
      <span class="form-dialog__subtitle">
            {{ subtitle() }}
      </span>
      <data-table-holidays
        :data="holidays"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import DataTableHolidays from '../DataTable/DataTableHolidays.vue';
export default {
  name: 'FormHolidaysPopup',
  props: {
    holidays: {
      type: Array,
      default: () => []
    },
    isLocationProduct: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DataTableHolidays
  },
  data() {
    return {
      alwaysOpen: true,
      errorMessage: null,
      carNumber: null,
      ecoCarHint: 'Not found'
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    validFromDate() {
      return this.toDate(this.discount.valid_from)
    },
    validToDate() {
      return this.toDate(this.discount.valid_to)
    },
    productName() {
      return 'Visitor parking'
    },
    discountPercentage() {
      return `${this.discount.discount_percentage}%`
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    toDate(timestamp) {
      const dateString = moment.unix(timestamp).format('DD.MM.YYYY')
      return dateString
    },
    subtitle() {
      if (this.isLocationProduct) {
        return 'This is your account default holidays. All your locations will by default have these discounts. To change the discount settings for this location only,  please contact parkman customer support'
      } else {
        return 'This is your account default holidays. All your locations will by default have these discounts. All locations will apply the discount.'
      }
    }
  }
}
</script>
<style lang="scss">
@import '@/style/common';

.v-text-field__details {
  padding: 0px !important;
}

.col:has(.v-input--is-focused) {
  .text-input-label {
    color: $color-brand;
  }
}

.form-dialog--new-user .v-input--is-focused .v-input__slot {
  border: 1px solid $color-brand !important;
}

.form-dialog--new-user .v-input.error--text .v-input__slot {
  border: 1px solid $color-danger !important;
}

.my-text-field ::v-deep .v-text-field__details .v-messages {
  display: flex;
  justify-content: flex-end;
}
</style>
