<template>
  <v-dialog
    v-model="showPopup"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>
      <v-tabs class="mt-4 mx-0" fixed-tabs color="primary" v-model="tab">
        <v-tabs-slider color="primary" />
        <v-tab class="smaller-tab ma-0 py-2 justify-start"> Mobile Web </v-tab>
        <!-- TODO WILL BE ADDED IN THE FEATURE -->
        <!-- <v-tab class="smaller-tab ma-0 py-2 justify-start"> Mobile app </v-tab> -->
        <v-tab class="smaller-tab ma-0 py-2 pl-2 justify-start">
          Integration
        </v-tab>

        <v-tabs-items v-model="tab" class="tab-warden-items mt-4">
          <v-tab-item class="tab-item">
            <h1 class="form-dialog__title">ParkMan mobile web</h1>

            <p>Your parking enforcement officers can check active parking payments via our mobile optimised web page. Share the link below with your parking officers. </p>
            <div class="tab-warden-items-code-block">
              <div class="tab-warden-items-code-block-title">For provider</div>
              <div
                class="tab-warden-items-code-block-link d-flex justify-space-between align-center"
              >
                <a
                  :href="`${warden.base_url}`"
                  class="tab-warden-items-code-block-link-text"
                  ref="webLink"
                  target="_blank"
                >
                  {{ warden.base_url }}
                </a>
                <img
                  class="cursor-pointer"
                  src="@/assets/copy-filled.svg"
                  alt="Copy link"
                  @click="copyCode('webLink')"
                />
              </div>
            </div>
          </v-tab-item>
          <!-- TODO WILL BE ADDED IN THE FEATURE -->

          <!-- <v-tab-item class="tab-item">
            <p>Device required: Android phone</p>
            <p>
              Make sure you allow the phone to install apps from other sources
              than the play store. Since the app is download directly from a
              webpage, the phone will ask if you for a special permission before
              you can install the app. You can also navigate to the following
              link from your Android phone.
            </p>
            <p><b>Credentials</b></p>
            <p>
              Username:{username}
              <br />
              Password: {password}
            </p>
            <div class="tab-warden-items-code-block">
              <div class="tab-warden-items-code-block-title">For provider</div>
              <div
                class="tab-warden-items-code-block-link d-flex justify-space-between align-center"
              >
                <div
                  class="tab-warden-items-code-block-link-text"
                  ref="appLink"
                >
                  https://provider-platform-test-84868687.parkmanapp.net/download/scanman-staging.apk
                </div>
                <v-icon
                  class="mr-2 cursor-pointer"
                  size="medium"
                  color="#b9b9b9"
                >
                  mdi-download
                </v-icon>
                <img
                  class="cursor-pointer"
                  src="@/assets/copy-filled.svg"
                  alt="Copy link"
                  @click="copyCode('appLink')"
                />
              </div>
            </div>
          </v-tab-item> -->
          <v-tab-item class="tab-item">
            <h1 class="form-dialog__title">Integration via API</h1>

            <p>
              If your parking enforcement officers are already using a handheld, internet-enabled device to check for payments from other payment providers, you can integrate your existing solution directly to the ParkMan, through our API.
            </p>
            <div class="tab-warden-items-code-block mb-4">
              <div class="tab-warden-items-code-block-title">Car search</div>
              <div
                class="tab-warden-items-code-block-link d-flex justify-space-between align-center"
              >
                <a
                  :href="`${warden.search_car_url}`"
                  class="tab-warden-items-code-block-link-text"
                  ref="carSearchLink"
                  target="_blank"
                >
                  {{ warden.search_car_url }}
                </a>
                <img
                  class="cursor-pointer"
                  src="@/assets/copy-filled.svg"
                  alt="Copy link"
                  @click="copyCode('carSearchLink')"
                />
              </div>
            </div>
            <div class="tab-warden-items-code-block mb-4">
              <div class="tab-warden-items-code-block-title">Area search</div>
              <div
                class="tab-warden-items-code-block-link d-flex justify-space-between align-center"
              >
                <a
                  :href="`${warden.search_area_url}`"
                  class="tab-warden-items-code-block-link-text"
                  ref="aresSearchLink"
                  target="_blank"
                >
                  {{ warden.search_area_url }}
                </a>
                <img
                  class="cursor-pointer"
                  src="@/assets/copy-filled.svg"
                  alt="Copy link"
                  @click="copyCode('aresSearchLink')"
                />
              </div>
            </div>
            <div
              class="tab-warden-items-code-block tab-warden-items-code-block-gray"
            >
              <div class="tab-warden-items-code-block-title">
                Result Format:
              </div>
              <div class="tab-warden-items-code-block-title">Success:</div>
              <div
                class="tab-warden-items-code-block-link d-flex justify-space-between align-center"
              >
                <pre>
{{ chosenTemplate }}
                </pre>
              </div>
              <div class="tab-warden-items-code-block-title">Failed:</div>
              <div
                class="tab-warden-items-code-block-link d-flex justify-space-between align-center"
              >
                <pre>
&lt;parkings&gt;
  &lt;Status&gt;0&lt;/Status&gt;
&lt;/parkings&gt;
                </pre>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-dialog>
</template>

<script>
import { templates } from './Templates.js';
import { mapGetters } from 'vuex'

export default {
  name: 'LinksForWardeningPopup',
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    warden: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tab: null
    }
  },
  computed: {
    ...mapGetters(['currentProductCurrencySymbol']),
    chosenTemplate() {
      // Return the template matching the ID, or a fallback message if invalid.
      return templates[this.warden.search_result_format_id] || 'Invalid template ID.';
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    copyCode(ref) {
      navigator.clipboard.writeText(this.$refs[ref].innerHTML)
      this.$store.dispatch('setSystemMessage', {
        type: 'success',
        message: this.$t('copied')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
::v-deep .v-tabs-bar {
  height: auto;
}
::v-deep .v-tabs-slider-wrapper {
  height: 2px !important;
}
.smaller-tab {
  border-bottom: 2px solid $color-line;
}
.tab-warden-items {
  p {
    color: $text-color-dark;
    font-size: 14px;
  }
  &-code-block {
    border-radius: 5px;
    border: 1px solid $color-line;
    font-size: 10px;
    padding: 12px 16px;

    &-gray {
      background-color: #f6f6f6;
    }

    &-title {
      font-weight: bold;
      color: $text-color-dark;
    }

    &-link {
      color: $color-brand;
      &-text {
        max-width: calc(100% - 25px);
      }
    }
  }
}
</style>
