<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="form-dialog form-dialog--new-user">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>

      <h1 class="form-dialog__title">Vehicle based discount - default</h1>
      <span class="form-dialog__subtitle">
            {{ subtitle() }} 
      </span>
      <v-form class="mt-8">
        <v-row dense>
          <v-col cols="12" style="height: 64px">
            <div class="text-input-label">List name</div>
            <v-text-field
              solo
              flat
              dense
              disabled
              background-color="#F6F6F6"
              v-model="discount.discount_list_name"
              class="pkmn-input"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" style="height: 64px">
            <div class="text-input-label">Valid from</div>
            <v-text-field
              solo
              flat
              dense
              disabled
              background-color="#F6F6F6"
              v-model="validFromDate"
              class="pkmn-input"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" style="height: 64px">
            <div class="text-input-label">Valid until</div>
            <v-text-field
              solo
              flat
              dense
              disabled
              background-color="#F6F6F6"
              v-model="validToDate"
              class="pkmn-input"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" style="height: 64px">
            <div class="text-input-label">Apply to product</div>
            <v-text-field
              solo
              flat
              dense
              disabled
              background-color="#F6F6F6"
              v-model="productName"
              class="pkmn-input"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" style="height: 64px">
            <div class="text-input-label">Discount</div>
            <v-text-field
              solo
              flat
              dense
              disabled
              background-color="#F6F6F6"
              v-model="discountPercentage"
              class="pkmn-input"
            >
            </v-text-field>
          </v-col>
        </v-row>
      <v-row v-if="!isLocationProduct">
            <v-col cols="12" style="height: 44px"></v-col>
      </v-row>
      <v-row dense v-if="!isLocationProduct">
          <v-col cols="12" style="height: 64px">
            <div class="text-input-label">Add / Remove / Search vehicle</div>
            <v-text-field
              solo
              flat
              dense
              background-color="#F6F6F6"
              v-model="carNumber"
              :hint="ecoCarHint"
              :error="error !== null"
              :error-messages="error" 
              persistent-hint
            >
            </v-text-field>
          </v-col>
      </v-row>

      <v-row class="form-dialog__button-wrap" v-if="!isLocationProduct">
        <v-col cols="4">
          <v-btn class="pkmn-button" outlined color="primary" @click="search">
            Search
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn
            class="pkmn-button"
            outlined
            color="primary"
            @click="remove"
          >
            Remove
          </v-btn>
        </v-col>
        <v-col cols="4">
          <v-btn
            class="pkmn-button pkmn-button--confirm pkmn-button--has-border"
            color="primary"
            @click="add"
          >
            Add
          </v-btn>
        </v-col>
      </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import merchantHelpers from '@/helpers/merchant'

export default {
  name: 'FormEcoCarDiscount',
  props: {
    discount: Object,
    isLocationProduct: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      alwaysOpen: true,
      errorMessage: null,
      carNumber: null,
      ecoCarHint: '',
      error: null
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    validFromDate() {
      return this.toDate(this.discount.valid_from)
    },
    validToDate() {
      return this.toDate(this.discount.valid_to)
    },
    productName() {
      return 'Visitor parking'
    },
    discountPercentage() {
      return `${this.discount.discount_percentage}%`
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async add() {
      const data = {
        car_num: this.carNumber,
        eco_cars_list_id: this.discount.list_id
      }
      const result = await merchantHelpers.addEcoCar(data)
      if (result) {
        this.ecoCarHint = 'Vehicle is added'
      } else {
        this.ecoCarHint = 'Something went wrong, try again'
      }
    },
    async remove() {
      const data = {
        car_num: this.carNumber,
        eco_cars_list_id: this.discount.list_id
      }
      const result = await merchantHelpers.removeEcoCar(data)
      if (result) {
        this.ecoCarHint = 'Vehicle is removed'
      } else {
        this.ecoCarHint = 'Something went wrong, try again'
      }
    },
    async search() {
      const data = {
        car_num: this.carNumber,
        eco_cars_list_id: this.discount.list_id
      }
      const car = await merchantHelpers.findEcoCar(data)
      if (car === null) {
        this.error = 'Vehicle is not on the list'
        this.ecoCarHint = 'Vehicle is not on the list'
      }
      else if (Object.keys(car).length !== 0) {
        this.error = null
        this.ecoCarHint = 'Vehicle is on the list'
      } else {
        this.error = 'Vehicle is not on the list'
        this.ecoCarHint = 'Vehicle is not on the list'
      }
    },
    toDate(timestamp) {
      const dateString = moment.unix(timestamp).format('DD.MM.YYYY')
      return dateString
    },
    subtitle() {
      if (this.isLocationProduct) {
        return 'This is your account default discount. All locations will apply the discount. To change the discount settings for this location only,  please contact parkman customer support'
      } else {
        return 'This is your account default discount. All locations will apply the discount.'
      }
    }
  }
}
</script>
<style lang="scss">
@import '@/style/common';

.v-text-field__details {
  padding: 0px !important;
}

.col:has(.v-input--is-focused) {
  .text-input-label {
    color: $color-brand;
  }
}

.form-dialog--new-user .v-input--is-focused .v-input__slot {
  border: 1px solid $color-brand !important;
}

.form-dialog--new-user .v-input.error--text .v-input__slot {
  border: 1px solid $color-danger !important;
}

.my-text-field ::v-deep .v-text-field__details .v-messages {
  display: flex;
  justify-content: flex-end;
}
</style>
