<template>
  <div>
    <v-data-table
      class="data__table wardens-table"
      ref="table"
      :headers="headers"
      :items="wardensList"
      fixed-header
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>
      <template v-slot:item.id="{ item }">
        <div class="mobile-row__action d-flex justify-end">
            <v-hover 
            v-if="auth.isParkmanAdmin() && item.type === 'location'"
            v-slot="{ hover }">
              <v-icon
              :color="hover ? 'primary' : '#B9B9B9'"
              class="delete-button"
              @click="showDeleteWardenPopup = true"
              >
                mdi-delete
              </v-icon>
              </v-hover>

              <v-hover v-slot="{ hover }">
                <v-icon
                :color="hover ? 'primary' : '#B9B9B9'"
                @click="showPopupForWarden(item)"
              >
                mdi-chevron-right
              </v-icon>
              </v-hover>

        </div>
      </template>

      <template v-slot:body.append>
        <tr class="add-row">
          <td class="add-row__text">Add Warden</td>
          <td>
            <div class="d-flex justify-end">
              <v-btn
              class="pkmn-button pkmn-button--no-padding"
              text
              color="primary"
              @click="handleAddWarden"
            >
              <img class="action-image" src="@/assets/button-add.svg" />
            </v-btn>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <links-for-wardening-popup
      :showPopup="showWardeningPopup"
      :warden="selectedWarden"
      @close="closeWardeningPopup"
    />
    <form-add-warden-popup
    v-if="showAddWardenPopup === true"
    :wardenList="wardenList"
    @close="showAddWardenPopup = false"
    @add="addWarden"
    />
    <form-delete-warden-popup
    v-if="showDeleteWardenPopup === true"
    :wardenList="wardenList"
    @close="showDeleteWardenPopup = false"
    @delete="deleteWarden"
    />
  </div>
</template>

<script>
import FormDeleteWardenPopup from './FormDeleteWardenPopup.vue'
import LinksForWardeningPopup from '@/components/Locations/AddVisitorParking/Subtabs/LinksForWardeningPopup.vue'
import FormAddWardenPopup from './FormAddWardenPopup.vue'
import merchantHelpers from '@/helpers/merchant'
import auth from '@/auth'

export default {
  name: 'SubtabWarden',
  components: { 
    LinksForWardeningPopup,
    FormAddWardenPopup,
    FormDeleteWardenPopup
   },
  props: {
    wardensList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      auth: auth,
      locationHeaders: [
        { text: this.$t('warden_name'), value: 'name' },
        { text: '', value: 'id', sortabl: false }
      ],
      merchantHeaders: [
        { text: this.$t('warden_name'), value: 'name' },
        { text: this.$t('locations'), value: 'locations' },
        { text: '', value: 'id', sortabl: false }
      ],
      mobileBreakpoint: 767,
      showWardeningPopup: false,
      selectedWarden: {},
      showAddWardenPopup: false,
      showDeleteWardenPopup: false,
      wardenList:[]
    }
  },
  computed: {
    headers() {
      return this.$route.name === 'Merchant'
        ? this.merchantHeaders
        : this.locationHeaders
    }
  },
  methods: {
    async showPopupForWarden(warden) {
      const data = await merchantHelpers.fetchWardenDetails(warden.id)
      this.selectedWarden = data
      this.showWardeningPopup = true
    },
    closeWardeningPopup() {
      this.selectedWarden = {}
      this.showWardeningPopup = false
    },
    async handleAddWarden() {
      if (this.wardenList.length === 0) {
        const data = await merchantHelpers.fetchWardenList()
        this.wardenList = data
      }
      this.showAddWardenPopup = true
    },
    addWarden(warden) {
      this.showAddWardenPopup = false
      this.$emit('add', warden)
    },
    deleteWarden(warden) {
      this.$emit('delete', warden)
    }
  }
}
</script>

<style lang="scss" scoped>

.wardens-table {
  &-chip {
    height: 12px;
    font-size: 10px;
  }
  .mobile-row__action {
    .theme--light:focus:before {
      opacity: 1 !important;
    }
  }
}

.add-row {
  border-top: 1px solid #eee;
  
  &:hover {
    background-color: #f8f8f8;
  }
}

.add-row__text {
  color: #333333;  /* Primary color */
  font-weight: 400;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
}
.delete-button {
  margin-right: 16px;
}
</style>
