<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="700px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="form-dialog form-dialog--new-user">
      <v-icon
        class="form-dialog__button form-dialog__button--close"
        @click="close"
      >
        mdi-close
      </v-icon>

      <h1 class="form-dialog__title">Location specific discounts</h1>
      <span class="form-dialog__subtitle">
            The locations listed below have discount settings that are different from your account default
      </span>
      <data-table-discount-locations
        :data="locations"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import DataTableDiscountLocations from '../DataTable/DataTableDiscountLocations.vue';
export default {
  name: 'FormDiscountLocationsPopup',
  props: {
    locations: {
      type: Array,
      default: () => []
    }
  },
  components: {
      DataTableDiscountLocations
  },
  data() {
    return {
      alwaysOpen: true,
      errorMessage: null,
      carNumber: null,
      ecoCarHint: 'Not found'
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    productName() {
      return 'Visitor parking'
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss">
@import '@/style/common';

.v-text-field__details {
  padding: 0px !important;
}

.col:has(.v-input--is-focused) {
  .text-input-label {
    color: $color-brand;
  }
}

.form-dialog--new-user .v-input--is-focused .v-input__slot {
  border: 1px solid $color-brand !important;
}

.form-dialog--new-user .v-input.error--text .v-input__slot {
  border: 1px solid $color-danger !important;
}

.my-text-field ::v-deep .v-text-field__details .v-messages {
  display: flex;
  justify-content: flex-end;
}
</style>
